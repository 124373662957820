export const Logo = () => {
  return (
    <svg
      width="111"
      height="18"
      viewBox="0 0 111 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13_19)">
        <path
          d="M7.6698 1.00665H0V17.7843H4.74567V13.3981H7.6698C12.2476 13.3981 15.1238 11.0253 15.1238 7.21437C15.1238 3.37949 12.2476 1.00665 7.6698 1.00665ZM7.38216 9.6591H4.74567V4.74567H7.38216C9.34755 4.74567 10.3302 5.65645 10.3302 7.21437C10.3302 8.7483 9.34755 9.6591 7.38216 9.6591Z"
          fill="#1E1E2E"
        />
        <path
          d="M20.5987 6.35152V4.7936H16.2604V17.7843H20.8144V11.8162C20.8144 9.53924 22.0846 8.60451 23.9302 8.60451C24.2658 8.60451 24.5533 8.62851 24.9608 8.67644V4.57788C23.0434 4.57788 21.5334 5.17709 20.5987 6.35152Z"
          fill="#1E1E2E"
        />
        <path
          d="M32.1638 18C36.454 18 39.498 15.1957 39.498 11.265C39.498 7.35818 36.454 4.57788 32.1638 4.57788C27.9214 4.57788 24.8296 7.35818 24.8296 11.265C24.8296 15.1957 27.9214 18 32.1638 18ZM32.1638 14.3808C30.6298 14.3808 29.4314 13.2543 29.4314 11.265C29.4314 9.29961 30.6298 8.19704 32.1638 8.19704C33.7217 8.19704 34.8961 9.29961 34.8961 11.265C34.8961 13.2543 33.7217 14.3808 32.1638 14.3808Z"
          fill="#1E1E2E"
        />
        <path
          d="M45.2507 18C49.4691 18 51.6742 16.1305 51.6742 13.7337C51.6742 8.58051 44.0284 10.522 44.0284 8.72436C44.0284 8.24496 44.5796 7.81356 46.1136 7.81356C47.264 7.81356 48.5583 8.05326 49.8526 8.77229L51.2188 5.68041C49.9245 4.96137 47.9112 4.57788 46.0896 4.57788C42.0151 4.57788 39.7621 6.44739 39.7621 8.89214C39.7621 14.0932 47.4318 12.1039 47.4318 13.8535C47.4318 14.4048 46.9285 14.7403 45.3945 14.7403C43.8127 14.7403 42.063 14.2849 40.8646 13.5419L39.4984 16.6578C40.7687 17.4487 43.0457 18 45.2507 18Z"
          fill="#1E1E2E"
        />
        <path
          d="M61.0187 4.5779C59.4847 4.5779 58.1185 5.05726 57.1119 5.99201V0H52.558V17.7843H57.1119V11.5046C57.1119 9.34755 58.2384 8.43675 59.7004 8.43675C61.0187 8.43675 61.8336 9.2277 61.8336 11.0972V17.7843H66.3875V10.3542C66.3875 6.32756 64.0626 4.5779 61.0187 4.5779Z"
          fill="#1E1E2E"
        />
        <path
          d="M73.7048 4.57788C71.6675 4.57788 69.4624 5.10518 67.9763 6.08787L69.5103 9.17976C70.3971 8.48466 71.7873 8.05326 73.0337 8.05326C74.8313 8.05326 75.7178 8.79629 75.7898 10.1145H73.2014C69.1748 10.1145 67.4251 11.6005 67.4251 13.9973C67.4251 16.2503 69.1508 18 72.2666 18C74.1601 18 75.4545 17.3529 76.1018 16.1065V17.7843H80.3438V10.5699C80.3438 6.44739 77.9228 4.57788 73.7048 4.57788ZM73.537 15.1238C72.4584 15.1238 71.8352 14.5726 71.8352 13.8056C71.8352 13.0147 72.3625 12.4873 73.8245 12.4873H75.7898V13.6138C75.4305 14.6445 74.5436 15.1238 73.537 15.1238Z"
          fill="#1E1E2E"
        />
        <path
          d="M86.478 6.35152V4.7936H82.14V17.7843H86.694V11.8162C86.694 9.53924 87.9637 8.60451 89.8095 8.60451C90.1447 8.60451 90.4327 8.62851 90.84 8.67644V4.57788C88.923 4.57788 87.4125 5.17709 86.478 6.35152Z"
          fill="#1E1E2E"
        />
        <path
          d="M104.802 11.3129C104.802 7.02262 101.686 4.57788 97.8517 4.57788C93.753 4.57788 90.7087 7.35818 90.7087 11.265C90.7087 15.1478 93.705 18 98.331 18C100.799 18 102.621 17.281 103.867 15.8908L101.47 13.3981C100.584 14.1651 99.7447 14.5246 98.4742 14.5246C96.7965 14.5246 95.67 13.7576 95.2867 12.4394H104.73C104.754 12.0799 104.802 11.6485 104.802 11.3129ZM97.875 7.81356C99.2895 7.81356 100.296 8.67644 100.536 10.0426H95.2147C95.4787 8.67644 96.4612 7.81356 97.875 7.81356Z"
          fill="#1E1E2E"
        />
        <path
          d="M107.741 18C109.299 18 110.474 16.8735 110.474 15.2916C110.474 13.6618 109.299 12.6072 107.741 12.6072C106.208 12.6072 105.009 13.6618 105.009 15.2916C105.009 16.8735 106.208 18 107.741 18Z"
          fill="#FF6644"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_19">
          <rect width="111" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Stockholm-icons / Navigation / Arrow-right">
        <rect
          id="Rectangle"
          opacity="0.3"
          x="5"
          y="13"
          width="2"
          height="14"
          rx="1"
          transform="rotate(-90 5 13)"
          fill="#0C1A4B"
        />
        <path
          id="Path 94"
          d="M11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L18.7071 12.7071C19.0857 12.3285 19.0989 11.7189 18.7372 11.3243L13.2372 5.32428C12.864 4.91716 12.2314 4.88965 11.8243 5.26285C11.4172 5.63604 11.3897 6.26861 11.7628 6.67572L16.6159 11.9699L11.2929 17.2929Z"
          fill="#0C1A4B"
        />
      </g>
    </svg>
  );
};

export const LocationPin = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33334 8.45258C3.33334 4.70473 6.31811 1.6665 10 1.6665C13.6819 1.6665 16.6667 4.70473 16.6667 8.45258C16.6667 12.1711 14.5389 16.5102 11.2191 18.0619C10.4452 18.4236 9.5548 18.4236 8.7809 18.0619C5.46111 16.5102 3.33334 12.1711 3.33334 8.45258Z"
        stroke="#1C274C"
        strokeWidth="1.5"
      />
      <circle cx="10" cy="8.3335" r="2.5" stroke="#1C274C" strokeWidth="1.5" />
    </svg>
  );
};

export const TiktokIcon = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_51_1294"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="19"
      >
        <path d="M0 0H15.931V18.8276H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_51_1294)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5961 -0.000976562C11.9023 2.6713 13.3738 4.2644 15.931 4.43323V7.43902C14.4497 7.58551 13.1503 7.09392 11.6408 6.16785V11.788C11.6408 18.9284 3.96166 21.1596 0.874759 16.0418C-1.10897 12.748 0.105932 6.96978 6.46924 6.73806V9.90771C5.96775 9.98855 5.47389 10.1112 4.99283 10.2743C3.57766 10.7601 2.7749 11.6696 2.99835 13.2743C3.42704 16.3472 8.98841 17.2567 8.52579 11.2517V0.00481652H11.5961V-0.000976562Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8127 0C19.7195 0 22.8994 3.11877 22.9979 7.002L23.0002 7.18749V15.8125C23.0002 19.7195 19.8813 22.8992 15.9982 22.9977L15.8127 23H7.1877C3.2803 23 0.100757 19.8813 0.00234985 15.998L0 15.8125V7.18749C0 3.28058 3.11849 0.100763 7.00219 0.00234793L7.1877 0H15.8127ZM15.8127 2.15625H7.1877C4.46535 2.15625 2.24178 4.32878 2.15884 7.03101L2.15644 7.18749V15.8125C2.15644 18.5345 4.32865 20.7584 7.0312 20.8413L7.1877 20.8437H15.8127C18.5344 20.8437 20.7586 18.6712 20.8416 15.969L20.844 15.8125V7.18749C20.844 4.46549 18.6711 2.2416 15.9692 2.15865L15.8127 2.15625ZM11.5002 5.75001C14.6755 5.75001 17.2502 8.32458 17.2502 11.5C17.2502 14.6755 14.6755 17.25 11.5002 17.25C8.32428 17.25 5.7502 14.6755 5.7502 11.5C5.7502 8.32458 8.32428 5.75001 11.5002 5.75001ZM11.5002 7.90624C9.519 7.90624 7.90645 9.51771 7.90645 11.5C7.90645 13.4809 9.519 15.0937 11.5002 15.0937C13.4808 15.0937 15.094 13.4809 15.094 11.5C15.094 9.51771 13.4808 7.90624 11.5002 7.90624ZM17.6815 4.55258C18.1044 4.55258 18.4475 4.89561 18.4475 5.31877C18.4475 5.74191 18.1044 6.08496 17.6815 6.08496C17.2579 6.08496 16.9148 5.74191 16.9148 5.31877C16.9148 4.89561 17.2579 4.55258 17.6815 4.55258Z"
        fill="white"
      />
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_51_1299)">
        <path
          d="M20.125 0H2.875C1.28944 0 0 1.28944 0 2.875V20.125C0 21.7106 1.28944 23 2.875 23H11.5V15.0937H8.625V11.5H11.5V8.62499C11.5 6.24308 13.4306 4.3125 15.8125 4.3125H18.6875V7.90624H17.25C16.4565 7.90624 15.8125 7.83149 15.8125 8.62499V11.5H19.4063L17.9688 15.0937H15.8125V23H20.125C21.7106 23 23 21.7106 23 20.125V2.875C23 1.28944 21.7106 0 20.125 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_1299">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StarRating = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.62766 4.50694C8.6832 2.6134 9.21097 1.66663 10 1.66663C10.7891 1.66663 11.3168 2.6134 12.3724 4.50694L12.6455 4.99682C12.9454 5.53491 13.0954 5.80395 13.3292 5.98147C13.5631 6.15898 13.8543 6.22488 14.4368 6.35667L14.9671 6.47665C17.0168 6.94042 18.0417 7.17231 18.2855 7.9564C18.5293 8.7405 17.8306 9.55752 16.4333 11.1916L16.0718 11.6143C15.6747 12.0787 15.4761 12.3108 15.3868 12.5981C15.2975 12.8853 15.3275 13.1951 15.3875 13.8146L15.4422 14.3786C15.6535 16.5588 15.7591 17.6489 15.1207 18.1335C14.4824 18.6181 13.5228 18.1762 11.6036 17.2926L11.1071 17.064C10.5617 16.8129 10.2891 16.6873 10 16.6873C9.71098 16.6873 9.43829 16.8129 8.89293 17.064L8.39642 17.2926C6.47725 18.1762 5.51767 18.6181 4.87931 18.1335C4.24095 17.6489 4.34659 16.5588 4.55785 14.3786L4.61251 13.8146C4.67254 13.1951 4.70256 12.8853 4.61324 12.5981C4.52392 12.3108 4.32538 12.0787 3.92829 11.6143L3.56677 11.1916C2.16939 9.55752 1.47071 8.7405 1.71454 7.9564C1.95837 7.17231 2.98323 6.94042 5.03297 6.47665L5.56326 6.35667C6.14573 6.22488 6.43696 6.15898 6.67081 5.98147C6.90465 5.80395 7.05462 5.53491 7.35458 4.99682L7.62766 4.50694Z"
        fill="#FAA500"
      />
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7L4 7"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M20 12L4 12"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 17L4 17"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};