import { useEffect, useRef } from "react";

import {testimonialData} from "../testimonials";

import { register } from "swiper/element/bundle";
import { Navigation } from "swiper/modules";

import ThemeWrapper from "./ThemeWrapper";
import { StarRating } from "../assets";

const TestimonialSlider = ({isFor}) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    // Register Swiper web component
    register();

    // Object with parameters
    const params = {
      slidesPerView: 1,
      module: [Navigation],

      breakpoints: {},
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);


  return (
    <section className="bg-white  relative">
     {isFor !== 'mobileMenu' ? <img src="https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712529005/PaperTear-02-min_h0opfs.png" alt="" className="relative top-0" /> : null }
      <ThemeWrapper>
        <section className="py-8 md:py-16 text-center">
          <h1 className="text-4xl md:text-5xl font-medium">
            What industry leaders are saying
          </h1>
          <section className="max-w-5xl mx-auto mt-10 md:mt-24">
            <swiper-container
              init="false"
              ref={swiperRef}
              navigation="true"
              speed="500"
              loop="true"
              autoplay="true"
              autoplay-delay="2500"
              autoplay-disable-on-interaction="false"
            >
              {testimonialData?.testimonials.map((item) => {
                return (
                  <swiper-slide key={item?.id}>
                    <div className="w-full flex flex-col justify-center items-center">
                      <div className="flex gap-1 mb-5">
                        <StarRating />
                        <StarRating />
                        <StarRating />
                        <StarRating />
                        <StarRating />
                      </div>
                      <p>{item?.testimony}</p>

                      <div className="flex flex-col items-center justify-center mt-10">
                        {item?.url.length ? (
                          <img
                            src={item?.url}
                            alt={item?.name}
                            className="w-24 h-24 rounded-full mb-5 object-cover"
                          />
                        ) : (
                          <span className="w-24 h-24 flex bg-main justify-center items-center text-3xl text-semibold text-white rounded-full mb-5 object-cover">
                            {item?.name.split(" ")[0][0]}
                            {item?.name.split(" ")[1][0]}
                          </span>
                        )}

                        <h6 className="font-medium">{item?.name}</h6>
                        <span className="text-sm text-main">{item?.title}</span>
                      </div>
                    </div>
                  </swiper-slide>
                );
              })}
            </swiper-container>
          </section>
        </section>
      </ThemeWrapper>
      <img src="https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712529004/PaperTear-01-min_jxjlxy.png" alt="" className="relative bottom-0" />
    </section>
  );
};

export default TestimonialSlider;
