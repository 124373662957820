import { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { Logo } from "./assets";
import Banner from "./components/Banner";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import CMSContextProvider from "./cmsContextProvider";

const Contact = lazy(() => import("./containers/Contact"));
const Home = lazy(() => import("./containers/Home"));
const Order = lazy(() => import("./containers/Order"));
const Review = lazy(() => import("./containers/Reviews"));
const Synopsis = lazy(() => import("./containers/Synopsis"));
const Author = lazy(() => import("./containers/Author"));

export const renderLoader = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <Logo />
  </div>
);

function App() {
  return (
    <CMSContextProvider>
      <Router>
        <Suspense fallback={renderLoader()}>
          <Banner />

          <Navigation />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/synopsis" element={<Synopsis />} />
            <Route path="/reviews" element={<Review />} />
            <Route path="/about-the-author" element={<Author />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </CMSContextProvider>
  );
}

export default App;
