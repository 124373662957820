import { Link, NavLink, useLocation } from "react-router-dom";
import { ArrowRight, Logo, MenuIcon } from "../assets";
import ThemeWrapper from "./ThemeWrapper";
import { useState } from "react";
import TestimonialSlider from "./TestimonialSlider";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const NavLinks = [
  {
    id: 1,
    name: "Home",
    url: "/",
  },
  {
    id: 2,
    name: "Synopsis",
    url: "/synopsis",
  },
  {
    id: 3,
    name: "The Author",
    url: "/about-the-author",
  },
  {
    id: 4,
    name: "Reviews",
    url: "/reviews",
  },
  {
    id: 5,
    name: "Contact",
    url: "/contact-us",
  },
];

const Navigation = () => {
  const location = useLocation().pathname;
  console.log({ location });
  const [visible, setVisible] = useState(false);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleClick = () => {
    setOpen(false);
    window.open("https://proshare.co/", "_blank");
  };

  return (
    <section
      className={` ${
        ["/", "/about-the-author", "/reviews", "/order"].includes(location)
          ? "bg-bgMain"
          : "bg-white"
      }`}
    >
      <ThemeWrapper>
        <section className="flex px-0 md:px-4 justify-between items-center w-full pt-8">
          <span className="cursor-pointer" onClick={() => onOpenModal()}>
            <Logo />
          </span>
          <div className="hidden gap-14 lg:flex">
            <div className="flex items-center gap-7">
              {NavLinks.map((item) => {
                return (
                  <NavLink
                    to={item?.url}
                    className={`font-normal hover:text-orange-300 ${
                      location === item.url
                        ? "text-main font-medium border-b-2 border-b-main  pb-2 ease-in-out"
                        : "text-black "
                    }`}
                    key={item?.id}
                  >
                    {item?.name}
                  </NavLink>
                );
              })}
            </div>
            <Link to="/order">
              <div className="bg-main pl-7 pr-[6px] flex gap-6 py-[6px] items-center rounded-full">
                <span className="text-white">Order now</span>
                <span className="bg-white flex items-center justify-center w-[42px] h-[42px] rounded-full">
                  <ArrowRight />
                </span>
              </div>
            </Link>
          </div>
          <section
            onClick={() => toggleVisibility()}
            className="cursor-pointer flex lg:hidden xl:hidden  bg-main rounded-3xl w-12 h-12 items-center justify-center"
          >
            <MenuIcon />
          </section>
        </section>
      </ThemeWrapper>

      {visible && (
        <section className="shadow-sm left-0 flex overflow-scroll lg:hidden bg-white top-0 fixed w-full  h-screen rounded-lg p-8 z-50 flex-col">
          <span
            onClick={() => setVisible(false)}
            className="bg-[#f3f3f3] cursor-pointer w-14 h-14 flex justify-center items-center rounded-full absolute right-7 top-7"
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.82 24L35.42 15.42C35.7966 15.0434 36.0082 14.5326 36.0082 14C36.0082 13.4674 35.7966 12.9566 35.42 12.58C35.0434 12.2034 34.5326 11.9918 34 11.9918C33.4674 11.9918 32.9566 12.2034 32.58 12.58L24 21.18L15.42 12.58C15.0434 12.2034 14.5326 11.9918 14 11.9918C13.4674 11.9918 12.9566 12.2034 12.58 12.58C12.2034 12.9566 11.9918 13.4674 11.9918 14C11.9918 14.5326 12.2034 15.0434 12.58 15.42L21.18 24L12.58 32.58C12.3925 32.7659 12.2438 32.9871 12.1422 33.2309C12.0407 33.4746 11.9884 33.736 11.9884 34C11.9884 34.264 12.0407 34.5254 12.1422 34.7692C12.2438 35.0129 12.3925 35.2341 12.58 35.42C12.7659 35.6075 12.9871 35.7563 13.2308 35.8578C13.4746 35.9593 13.736 36.0116 14 36.0116C14.264 36.0116 14.5254 35.9593 14.7692 35.8578C15.0129 35.7563 15.2341 35.6075 15.42 35.42L24 26.82L32.58 35.42C32.7659 35.6075 32.9871 35.7563 33.2308 35.8578C33.4746 35.9593 33.736 36.0116 34 36.0116C34.264 36.0116 34.5254 35.9593 34.7691 35.8578C35.0129 35.7563 35.2341 35.6075 35.42 35.42C35.6075 35.2341 35.7562 35.0129 35.8578 34.7692C35.9593 34.5254 36.0116 34.264 36.0116 34C36.0116 33.736 35.9593 33.4746 35.8578 33.2309C35.7562 32.9871 35.6075 32.7659 35.42 32.58L26.82 24Z"
                fill="black"
              />
            </svg>
          </span>
          <section className="mt-14">
            {NavLinks?.map((item) => {
              return (
                <Link
                  onClick={() => setVisible(false)}
                  to={item?.url}
                  className="block text-black text-base font-medium mb-5"
                  key={item?.id}
                >
                  {item?.name}
                </Link>
              );
            })}
          </section>

          <div className="flex  pb-5 md:pb-0 w-full">
            <Link
              onClick={() => setVisible(false)}
              to="/order"
              className="w-full flex"
            >
              <div className="bg-main pl-7 pr-[6px] w-full flex gap-6 py-[6px] items-center justify-between rounded-full">
                <span className="text-white">Order now</span>
                <span className="bg-white flex items-center justify-center w-[42px] h-[42px] rounded-full">
                  <ArrowRight />
                </span>
              </div>
            </Link>
          </div>

          <TestimonialSlider isFor="mobileMenu" />
        </section>
      )}

      <Modal open={open} onClose={onCloseModal} center>
        <div className="py-8 w-full flex flex-col justify-center items-center px-8">
          <h2 className="font-medium text-lg">
            Would you like to visit Proshare?
          </h2>

          <section className="flex gap-2 mt-5 ">
            <button
              onClick={() => handleClick()}
              className="bg-main text-white text-sm font-medium rounded-lg px-4 py-2"
            >
              Yes Please!
            </button>
            <button
              onClick={() => onCloseModal()}
              className="bg-gray-700 text-sm text-white font-medium rounded-lg px-4 py-2"
            >
              Cancel
            </button>
          </section>
        </div>
      </Modal>
    </section>
  );
};

export default Navigation;
