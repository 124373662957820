import { Link } from "react-router-dom";
import announcement from "../assets/annoucement.png";

const Banner = () => {
  return (
    <section className="bg-black px-5 py-5 text-center flex justify-center items-center">
      <img src={announcement} alt="annoucement" className="w-10" />
      <span className="text-sm text-white font-normal">
        Want to preview the book? {" "}
        <Link to="/synopsis" className="text-main underline">
          Read the synopsis here
        </Link>
      </span>
    </section>
  );
};

export default Banner;
