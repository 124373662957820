import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Page from "./components/Page";

storyblokInit({
  accessToken: "QVlaORs7KNEMdM7IKrnkSAtt",
  use: [apiPlugin],
  components: {
    page: Page,
  },
  cache: {
    clear: "auto",
    type: "memory",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
