export const testimonialData = {
  testimonials: [
    {
      id: "10999",
      name: "H.R.H. Sanusi Lamido SANUSI, CON",
      title:
        "The 14th Emir of the Ancient City of Kano and former Governor of the Central Bank of Nigeria",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713431790/sanusi_eyrszp.png",
      testimony:
        "Over the years, Proshare has filled a gap in the financial market space as a market watchdog demanding accountability and whose intervention calms the storm amidst raging market winds. ",
    },
    {
      id: "1119",
      name: "Dr Yemi Kale",
      title:
        "Chief Economist and Managing Director, Research & International Cooperation, Afreximbank.  Former Statistician-General, National Bureau of Statistics ",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713431791/DrYemiKale_igegpn.png",
      testimony:
        "I had the privilege of working with Proshare on the NBS Project. I commend the excellence and professionalism the team displayed in discharging their duties. Their display of professionalism and dedication is unwavering ",
    },
    {
      id: "112",
      name: "Francis Osuyah",
      title: "CEO, FROS CAPITAL",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712392719/307651c59c5d6e90fe9cc61bfd471f90.300x4007C_jrrclz.jpg",
      testimony:
        "The strategic insights provided by Proshare through its interventions and thought-leading articles in the financial market service space cannot go unnoticed",
    },
    {
      id: "113",
      name: "Mrs. Folake Ani-Mumuney",
      title: "Group Head, Marketing & Corp. Comm, First Bank of Nigeria",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712393805/FAM-min_nn9ho5.jpg",
      testimony:
        "We know that Proshare is competent and capable of delivering on financial matters, having exhibited high professionalism and consistency in discharging required mandates",
    },
    {
      id: "13",
      name: "Victor ADEBAYO",
      title: "Hospitals' Management Board Akure",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448817/images_s3ptvz.jpg",
      testimony:
        "The analyst reports by Proshare remain a quintessential dedication to promptness, consistency, robustness, data accuracy, and market news with research. This ingenuity and innovation are by no small means a requisite tool for effective investment decisions.",
    },
    {
      id: "1klad",
      name: "Akin' ADENIYI",
      title: "Chief Relationship Officer, Kingthrones Limited",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712396492/1701974312-Akin.jpg_k4dfpx.webp",
      testimony:
        "Information asymmetry is a globally acknowledged challenge in financial markets, but to varying degrees of notoriety, and I believe this cause that Olufemi Awoyemi's Proshare has championed for Nigeria, in particular, deserves more than high commendations.",
    },
    {
      id: "18102",
      name: "Dr Babatunde OBRIMAH",
      title: "Chief Operating Officer, Fintech NGR",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712397456/WhatsApp-Image-2021-06-06-at-21.01.57-1_ej3lrs.jpg",
      testimony:
        "For Proshare, it is not about profit but making a difference and creating an impact that matters towards stimulating the economy through data aggregation, mining, and dissemination.",
    },
    {
      id: "10003",
      name: "Dr Funke MEDUN",
      title: "Chief Executive Officer, Leapworld",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712397707/1653911115531_z64th9.jpg",
      testimony:
        "I recommend this book to all who want to achieve enduring success in their genuine quest for excellence and to every business school in Nigeria and international schools as a Nigerian enterprise case study.",
    },
    {
      id: "14",
      name: "Charles ITUAH",
      title:
        "MSc International Business and Entrepreneurship, University of Essex",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712398648/1708697590540_sn6jds.jpg",
      testimony:
        "I must say that Proshare is doing a great job interpreting what the news means and educating youths like me about the financial market and Marco's economy. Your content gives me more confidence that the country will make progress with the right policy execution and implementation.",
    },
    {
      id: "15",
      testimony:
        "What Olufemi Awoyemi has done in birthing Proshare is turn a nagging idea/concept of his into reality for the improvement of our financial and business landscape. This book, “Beyond Profit,” tells the story from birth to date.",
      name: "Jojo DAWODU",
      title: "Partner, Public Sector & Corporates, View LLC",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448903/team-3-pro_cnf8kl.jpg",
    },
    {
      id: "1509122",
      testimony:
        "In conducting business with our firm, Proshare delivered reliable market news and accurate data, enabling the efficient execution of our strategy.",
      name: "Mr. Bolaji OKUSAGA",
      title: "Former Managing Director, The Quadrant Company",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712399602/1517696220209_cdlv4a.jpg",
    },
    {
      id: "16",
      testimony:
        "The lessons shared here about values, attitudes, and character required to build upon an idea should be useful for aspiring entrepreneurs and intrapreneurs.",
      name: "Dr Segun AINA, OFR",
      title: "President, Africa Fintech Network",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712399799/1623531217152_sp3osx.jpg",
    },
    {
      id: "75",
      testimony:
        "This book is not just a recommendation; it's necessary for anyone, from seasoned entrepreneurs to those just starting, to be ready to face the challenges of the business world. Above all, the courage to stand up, disagree, or say no is a non-negotiable trait",
      name: "Oye ADEKOLA",
      title: "Chief Executive, Countdown Tracking Limited",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448685/Adekola_t29ynz.jpg",
    },
    {
      id: "109125",
      testimony:
        "It is to the credit of Olufemi Awoyemi and his co-travelers on the journey through the conception, inception, and progression stages of the company’s life cycle that the Proshare story is now eminently worth telling and profoundly worth listening to",
      name: "Dr. Ayo TERIBA",
      title: "Chief Executive Officer, Economic Associates",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712400407/Dr-Ayodele-Teriba-v2023-min_ytrbpm.png",
    },
    {
      id: "15082w12",
      testimony:
        "Over the years, I have not been disappointed, as Proshare has provided the needed deeper analysis of happenings and events within the economy, both in the private and public sectors, and has given insight into hitherto unclear areas. I have particularly found Proshare to be a good provider for checks and balances in the system, especially where issues of regulation, governance, and corporate leadership come into play",
      name: "Bamidele Alimi, FNIM, M.IoD",
      title: "Director General/CEO, Institute of Directors",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713445328/bamidele-1_bqqpxy.png",
    },
    {
      id: "123",
      testimony:
        "The book Beyond Profit: How a Nigerian Company Built a Culture of Credibility is a classic in entrepreneurship and business growth that I can relate to. It provides a wealth of insights into how organizations can foster innovation and resilience in the face of disruptive changes, challenges, and crises. I congratulate Olufemi Awoyemi and the entire Proshare team on this book. It is a must-read for young entrepreneurs. ",
      name: "Femi OTEDOLA, CON",
      title: "Chairman of Geregu Power Plc  and First Bank HoldCo  Plc",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713588420/images_34_lnhfxv.jpg",
    },
    {
      id: "12390",
      testimony:
        "Unveiling the corporate biography of Proshare in a unique manner, “Beyond Profit” highlights the organisation’s selfless focus on financial reporting and critique of the Nigerian capital market in a reader-friendly manner.",
      name: "Tony CHIEJINA",
      title: "Chief Corporate Communications Officer, Dangote Group.",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713588832/antony-chiejina-2021-influence-100_uwzevx.jpg",
    },

    {
      id: "1239900",
      testimony:
        "This is an excellent book. It is a great addition to the understanding of the Nigeria's nascent, but growing business environment. ",
      name: "Dr. Ogho OKITI ",
      title:
        "CEO of ThinkBusiness Africa, former SA to  the Chief Economic Adviser 2012-2015",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713599836/images_35_lofvlk.jpg",
    },
  ],
  reviews: [
    {
      id: "1",
      name: "Akin' ADENIYI",
      title: "Chief Relationship Officer, Kingthrones Limited",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712396492/1701974312-Akin.jpg_k4dfpx.webp",
      testimony:
        "A commitment to bridging the traditional information gap between the markets and the consumers of financial products and services in a country like Nigeria can be as risky as it is a thankless service. I know this and am saying it with all due sense of responsibility as someone with almost 34 years' cognate experience. This commitment is more of a crusade - a nuisance value - at the heart of any emerging market's political economy than the passion of any individual or group genuinely expecting to bridge that information gap. Information asymmetry is a globally acknowledged challenge in financial markets, but to varying degrees of notoriety, and I believe this cause that Olufemi Awoyemi's Proshare has championed for Nigeria, in particular, deserves more than high commendations. I believe Proshare has been a journey worth every single experience, and it can only get better in the days and years ahead. I can only wish my dear friend and brother much more, way more than just profit.",
    },
    {
      id: "1092135",
      testimony:
        "Proshare is an organization that I have found quite intriguing since my first contact with it about a decade ago. My relationship with the founder and my knowledge of his pedigree got me quite interested in the organization and what exactly it stood for. Over the years, I have not been disappointed, as Proshare has provided the needed deeper analysis of happenings and events within the economy, both in the private and public sectors, and has given insight into hitherto unclear areas. I have particularly found Proshare to be a good provider for checks and balances in the system, especially where issues of regulation, governance, and corporate leadership come into play. The sides that are shrouded in mystery are revealed, assessed, and analysed for greater and better understanding – thanks to Proshare. In summary, I would say, but for Proshare, things could have been worse.",
      name: "Bamidele Alimi, FNIM, M.IoD",
      title: "Director General/CEO, Institute of Directors",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713445328/bamidele-1_bqqpxy.png",
    },
    {
      id: "12",
      name: "Dr Babatunde OBRIMAH",
      title: "Chief Operating Officer, Fintech NGR",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712397456/WhatsApp-Image-2021-06-06-at-21.01.57-1_ej3lrs.jpg",
      testimony:
        "Proshare has played a leadership role in the evolution of the Fintech ecosystem in Nigeria and the continent. As one of the three founding members of the Fintech Association of Nigeria, the organization deployed its resources and expertise in ideating and strategizing the take-off of FintechNGR and has since continued to strategically contribute to the growth of the Association. It plays the same role for the Africa Fintech Network, a network of thirty-five fintech Associations across Africa. For Proshare, it is not about profit but making a difference and creating an impact that matters towards stimulating the economy through data aggregation, mining, and dissemination. Contribution to thought leadership in the country and beaming media lights on key enablers of the economy, especially the financial sector. Proshare is a brand that we are extremely proud of.",
    },
    {
      id: "13",
      name: "Dr Funke MEDUN",
      title: "Chief Executive Officer, Leapworld",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712397707/1653911115531_z64th9.jpg",
      testimony:
        "My experience with Proshare and the insights from the author’s independent interviews with more than a hundred prominent corporate executives and leaders in Nigeria in the making of this book give credence to the fact that Proshare is on a mission and a company to look out for. I recommend this book to all who want to achieve enduring success in their genuine quest for excellence and to every business school in Nigeria and international schools as a Nigerian enterprise case study.",
    },
    {
      id: "14",
      name: "Charles ITUAH",
      title:
        "MSc International Business and Entrepreneurship, University of Essex",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712398648/1708697590540_sn6jds.jpg",
      testimony:
        "I viewed Proshare’s website and immediately became interested in the content. I must say that Proshare is doing a great job interpreting what the news means and educating youths like me about the financial market and Marco's economy. Your content gives me more confidence that the country will make progress with the right policy execution and implementation. I have the utmost respect for your approach to tackling issues in key areas of investment, banking, finance, and the economy. Thank you for your content.",
    },
    {
      id: "10195",
      testimony:
        "What Olufemi Awoyemi has done in birthing Proshare is turn a nagging idea/concept of his into reality for the improvement of our financial and business landscape. Proshare has earned a reputation as a well-respected research and analytical company, a source of credible information for blue-chip organizations in the business and financial world. This book, “Beyond Profit,” tells the story from birth to date. Kudos must be given to Tosin Adeoti for the inside gist and to Olufemi and Proshare.  Keep climbing, for the world belongs to the fearless and relentless!",
      name: "Jojo DAWODU",
      title: "Partner, Public sector & Corporates, View LLC",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448903/team-3-pro_cnf8kl.jpg",
    },

    {
      id: "75",
      testimony:
        "Beyond Profit is a testament to Lisa Olufemi Awoyemi's extraordinary resilience. From a personal encounter with corporate fraud through his late dad to his unwavering determination to rectify the situation in Corporate Nigeria, the book's protagonist has consistently defied the norms of Nigerian financial journalism. His success in passing the baton to new management is a testament to his indomitable spirit. The trajectory of the Proshare brand is a veritable case study of grit to run a business in this landscape. Fortunately, Awoyemi was lucky to have an outstanding foundational team that stood like the rock of Gibraltar in weathering the corporate, economic, and financial news reporting storm. Despite his extensive network in Corporate Nigeria, Lisa Awoyemi's foresight and judgment remain unclouded. The Proshare brand's success is built not just on reporting the news but also on an unwavering commitment to integrity and unparalleled fair reporting. This book is not just a recommendation; it's necessary for anyone, from seasoned entrepreneurs to those just starting, to be ready to face the challenges of the business world. Above all, the courage to stand up, disagree, or say no is a non-negotiable trait.",
      name: "Oye ADEKOLA",
      title: "Chief Executive, Countdown Tracking Limited",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448685/Adekola_t29ynz.jpg",
    },
    {
      id: "16",
      testimony:
        "Proshare, is reputed to have created a formidable niche across every financial services industry sector through credible and trustworthy reporting and service delivery. The success of Proshare cannot be documented or explored without its employees, whose resilience, deep commitment, and hard work have positioned the company globally. The lessons shared here about values, attitudes, and character required to build upon an idea should be useful for aspiring entrepreneurs and intrapreneurs. Like every organization, Proshare has faced criticism from the public and encountered various challenges, but the resilience embedded in the system has ensured its sustenance and growth. Three key words that have kept Proshare aloft in its engagements and service delivery are integrity, transparency, and agility. No doubt, this organization still has a lot more to offer for global financial analysis and reporting, with particular emphasis on Nigeria and the continent of Africa, especially with the current threat of another round of instability in the global financial services industry. This book is a must-read for everyone. ",
      name: "Dr Segun AINA, OFR",
      title: "President, Africa Fintech Network",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712399799/1623531217152_sp3osx.jpg",
    },

    {
      id: "187126325",
      testimony:
        "I have watched Proshare evolve from a provider of useful financial information for individuals and companies that seek it to a reputable repository of knowledge on the financial markets, the corporate giants that move them, and the regulatory watchdogs that keep them in check. Leaders of markets, companies, and regulatory agencies now place a high premium on the unbiased opinions that Proshare regularly provides about their own conduct and the conduct of others in the marketplace. It is to the credit of Olufemi Awoyemi and his co-travelers on the journey through the conception, inception, and progression stages of the company’s life cycle that the Proshare story is now eminently worth telling and profoundly worth listening to. I am confident that this will only be the first in a series of volumes that capture the story as it unfolds.",
      name: "Dr. Ayo TERIBA",
      title: "Chief Executive Officer, Economic Associates",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712400407/Dr-Ayodele-Teriba-v2023-min_ytrbpm.png",
    },

    {
      id: "10900",
      name: "H.R.H. Sanusi Lamido SANUSI, CON",
      title: `<p>The 14th Emir of the Ancient City of Kano <br /> and former Governor of the Central Bank of Nigeria`,
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713431790/sanusi_eyrszp.png",
      testimony:
        "I commend Proshare for this wonderful book detailing the corporate journey of one of Nigeria's leading financial information services. Over the years, Proshare has filled a gap in the financial market space as a market watchdog demanding accountability and whose intervention calms the storm amidst raging market winds.",
    },
    {
      id: "1509122",
      testimony:
        "Over the years, Proshare has been responsible for offering valuable knowledge services to our company. These services help us understand the economy and financial markets through insightful data and timely, objective, and consistent professional service delivery.  In conducting business with our firm, Proshare delivered reliable market news and accurate data, enabling the efficient execution of our strategy.",
      name: "Mr. Bolaji OKUSAGA",
      title: "Former Managing Director, The Quadrant Company",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712399602/1517696220209_cdlv4a.jpg",
    },
    {
      id: "11109",
      name: "Dr Yemi Kale",
      title:
        "Chief Economist and Managing Director, Research & International Cooperation, Afreximbank.  Former Statistician-General, National Bureau of Statistics ",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713431791/DrYemiKale_igegpn.png",
      testimony:
        "I have the privilege of working with Proshare on the NBS Project. I commend the excellence and professionalism the team displayed in discharging their duties. Their display of professionalism and dedication is unwavering",
    },
    {
      id: "113",
      name: "Folake Ani-Mumuney",
      title: `Group Head, Marketing & Corp. Comms, <br /> First Bank of Nigeria`,
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712393805/FAM-min_nn9ho5.jpg",
      testimony:
        "We have known and engaged the services of Proshare for over a decade to provide professional services. We know that Proshare is competent and capable of delivering on financial matters, having exhibited high professionalism and consistency in discharging required mandates.",
    },
    {
      id: "13",
      name: "Victor ADEBAYO",
      title: "Hospitals' Management Board Akure",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713448817/images_s3ptvz.jpg",
      testimony:
        "The analyst reports by Proshare remain a quintessential dedication to promptness, consistency, robustness, data accuracy, and market news with research. This ingenuity and innovation are by no small means a requisite tool for effective investment decisions. I strongly recommend Proshare’s platform as a bona fide investment companion for any individual or corporate investor who wants to profit from the power of information.",
    },
    {
      id: "112",
      name: "Francis Osuyah",
      title: "CEO, FROS CAPITAL",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1712392719/307651c59c5d6e90fe9cc61bfd471f90.300x4007C_jrrclz.jpg",
      testimony:
        "The strategic insights provided by Proshare through its interventions and thought-leading articles in the financial market service space cannot go unnoticed. Proshare must continue to lead the way in financial market services and become the benchmark in Africa.",
    },
    {
      id: "12390",
      testimony:
        "Unveiling the corporate biography of Proshare in a unique manner, “Beyond Profit” highlights the organisation’s selfless focus on financial reporting and critique of the Nigerian capital market in a reader-friendly manner. Proshare, owned and established in 2006 by Mr. Olufemi Awoyemi, has bridged the vital gap between investors and the capital market, and offers top-rate services spanning market intelligence, strategic advisory, impact research, digital media, and stakeholder relations. Like a visually appealing work of art, the author, Tosin Adeoti, has brought a refreshing perspective to the noble motive behind Proshare’s work and positive impact on the economy.",
      name: "Tony CHIEJINA",
      title: "Chief Corporate Communications Officer, Dangote Group.",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713588832/antony-chiejina-2021-influence-100_uwzevx.jpg",
    },
    {
      id: "123990",
      testimony:
        "This is an excellent book. It is a great addition to the understanding of the Nigeria's nascent, but growing business environment. The greatest fascination is that it was written in the first place—providing a frank account of Proshare founder's failures, challenges, and triumphs. It is that frankness that not only makes the book compelling to read, but also serves as pointers to many businesses that seek excellence and credibility in today's business environment.",
      name: "Dr. Ogho OKITI ",
      title:
        " CEO of ThinkBusiness Africa, former SA to  the Chief Economic Adviser 2012-2015 ",
      url: "https://res.cloudinary.com/ddu5xs8lc/image/upload/v1713599836/images_35_lofvlk.jpg",
    },
  ],
};
