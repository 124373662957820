import ThemeWrapper from "./ThemeWrapper";

import Selar from "../assets/selar.png";
import Koboboosk from "../assets/kobobooks.png";
import Rovingheights from "../assets/rovingheights.png";
import Amazon from "../assets/amazon.png";
import ProshareStore from "../assets/Proshare Store logo.png";
import {
  FacebookIcon,
  InstagramIcon,
  LocationPin,
  TiktokIcon,
} from "../assets";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <section className="pt-16 bg-bgMain">
      <ThemeWrapper>
        <section className="flex w-full flex-col text-center py-10">
          <h5 className="font-semibold text-2xl">Available Here</h5>
          <h6 className="font-medium text-base mb-4">
            Click the store below, to get the book using your favourite platform
          </h6>

          <div className="grid grid-cols-3 grid-rows-1 gap-4 md:grid-rows-1 md:grid-col-5 md:gap-6 mt-4">
            <a
              href="https://selar.co/9qf12l?fbclid=IwAR23FIab3DZv8rKFBcy-ATNVCFp9bsNLjzP370zGA__5IJ4xnnoVWsEyHoY"
              target="_blank"
              rel="noreferrer"
              className="flex items-center md:justify-center"
            >
              <img src={Selar} alt="selar store" className="h-8 md:h-10" />
            </a>

            <a
              href="https://www.kobo.com/ww/en/ebook/beyond-profit?fbclid=IwAR0aov9DbXJxOs8Mh8GwVPahlfUWLG_pA_frAyNrUSAEeUMSRW8nbqPE--M"
              className="flex items-center md:justify-center"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Koboboosk} alt="selar store" className="h-5 md:h-7" />
            </a>

            <a
              href="https://www.amazon.com/Beyond-Profit-Tosin-Adeoti/dp/B0CR7SKVMB/ref=sr_1_5?crid=E1MB3SUULWB0&keywords=beyond+profit&qid=1706621068&sprefix=beyoud%2Caps%2C1761&sr=8-5&fbclid=IwAR0XCxYez5YWAYvK2wDy1jRhc-wmQeJUHPY2vsPBQe09QsLD9f9OQNIlb_A"
              className="flex items-center md:justify-center"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Amazon} alt="selar store" className="h-10 md:h-14" />
            </a>

            <a
              href="https://rhbooks.com.ng/product/beyond-profit/?fbclid=IwAR2lFG-iZ21scY1_XVyMl1DZH1PCkNBQVNCZAhZIRUKPWHaTvk497AM6QoM"
              className="flex items-center md:justify-center"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Rovingheights}
                alt="selar store"
                className="h-7 md:h-10"
              />
            </a>
            <a
              href="https://maps.app.goo.gl/4AGQ5AMtNYgErieQA"
              className="flex items-center md:justify-center"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ProshareStore}
                alt="selar store"
                className="h-3 md:h-3"
              />
            </a>
          </div>
        </section>
        <section className="w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className=" p-4">
              <LocationPin />
              <h6 className="font-semibold text-xl">Lagos Mainland</h6>
              <p>
                <a href="https://maps.app.goo.gl/aDBTHapZoUwy2Lhb6">
                  28, Ogunlana Drive, Surulere, Lagos, Nigeria
                </a>
              </p>
            </div>

            <div className=" p-4">
              <LocationPin />
              <h6 className="font-semibold text-xl">Lagos Island</h6>
              <p>
                <a href="https://maps.app.goo.gl/9Qz9jwUVPybVUp9N6">
                  Retail Boulevard, Landmark Center, Oniru Water Corporation
                  way, Oniru Lagos
                </a>
              </p>
            </div>

            <div className=" p-4">
              <LocationPin />
              <h6 className="font-semibold text-xl">Abuja</h6>
              <p>
                <a href="https://maps.app.goo.gl/BUbaH6Z7ZokuwAcA9">
                  Suite 4.1 Block B CVS Plaza, 145 Adetokunbo Ademola (Opposite
                  Otis Carpet), Wuse 2, Abuja
                </a>
              </p>
            </div>

            <div className=" p-4">
              <LocationPin />
              <h6 className="font-semibold text-xl">Abuja</h6>
              <p className="mt-2">
                <a href="https://maps.app.goo.gl/sDoqYtStD29HQ2FP7">
                  Shop S1, 2nd Floor H-Medix Building, City Center Mall, Gimbaya
                  St, City Centre Garki, Abuja
                </a>
              </p>
            </div>
            <div className=" p-4">
              <LocationPin />
              <h6 className="font-semibold text-xl">Proshare Store</h6>
              <p className="mt-2">
                <a href="https://maps.app.goo.gl/4AGQ5AMtNYgErieQA">
                  Plot 590b, Lekan Asuni Close off Toyin Omotosho street, Omole
                  Phase 2, Isheri Olowora 101224, Ikeja, Lagos
                </a>
              </p>
              <p className="mt-2">
                <a href="tel:09024075284"> 09024075284</a>
              </p>
            </div>
          </div>
        </section>
      </ThemeWrapper>
      <section className="bg-black py-10 flex flex-col justify-center items-center gap-7 mt-10">
        <div className="flex items-center gap-2">
          <p className="text-white font-medium">Powered By</p>
          <a href="https://proshare.co/">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="24"
              viewBox="0 0 148 24"
              fill="none"
            >
              <script xmlns="" />
              <script xmlns="" />
              <script xmlns="" />
              <path
                d="M10.2264 1.34221H0V23.7124H6.32756V17.8642H10.2264C16.3302 17.8642 20.1651 14.7004 20.1651 9.61917C20.1651 4.50599 16.3302 1.34221 10.2264 1.34221ZM9.84288 12.8788H6.32756V6.32756H9.84288C12.4634 6.32756 13.7736 7.54194 13.7736 9.61917C13.7736 11.6644 12.4634 12.8788 9.84288 12.8788Z"
                fill="#fff"
              />
              <path
                d="M27.4649 8.46871V6.39148H21.6806V23.7124H27.7525V15.755C27.7525 12.719 29.4462 11.4727 31.907 11.4727C32.3544 11.4727 32.7378 11.5047 33.2811 11.5686V6.10386C30.7245 6.10386 28.7112 6.9028 27.4649 8.46871Z"
                fill="#fff"
              />
              <path
                d="M42.8851 24C48.6054 24 52.664 20.261 52.664 15.02C52.664 9.81092 48.6054 6.10386 42.8851 6.10386C37.2286 6.10386 33.1061 9.81092 33.1061 15.02C33.1061 20.261 37.2286 24 42.8851 24ZM42.8851 19.1744C40.8398 19.1744 39.2419 17.6724 39.2419 15.02C39.2419 12.3995 40.8398 10.9294 42.8851 10.9294C44.9623 10.9294 46.5282 12.3995 46.5282 15.02C46.5282 17.6724 44.9623 19.1744 42.8851 19.1744Z"
                fill="#fff"
              />
              <path
                d="M60.3343 24C65.9588 24 68.8989 21.5073 68.8989 18.3116C68.8989 11.4407 58.7045 14.0293 58.7045 11.6325C58.7045 10.9933 59.4395 10.4181 61.4848 10.4181C63.0187 10.4181 64.7444 10.7377 66.4701 11.6964L68.2917 7.5739C66.566 6.61518 63.8816 6.10386 61.4528 6.10386C56.0201 6.10386 53.0161 8.59654 53.0161 11.8562C53.0161 18.7909 63.2424 16.1385 63.2424 18.4714C63.2424 19.2064 62.5713 19.6538 60.526 19.6538C58.4169 19.6538 56.084 19.0466 54.4861 18.0559L52.6645 22.2104C54.3583 23.265 57.3942 24 60.3343 24Z"
                fill="#fff"
              />
              <path
                d="M81.3583 6.10386C79.313 6.10386 77.4914 6.74301 76.1492 7.98935V0H70.0773V23.7124H76.1492V15.3395C76.1492 12.4634 77.6512 11.249 79.6006 11.249C81.3583 11.249 82.4448 12.3036 82.4448 14.7963V23.7124H88.5167V13.8056C88.5167 8.43675 85.4168 6.10386 81.3583 6.10386Z"
                fill="#fff"
              />
              <path
                d="M98.273 6.10386C95.5566 6.10386 92.6165 6.80692 90.6351 8.11718L92.6804 12.2397C93.8628 11.3129 95.7164 10.7377 97.3782 10.7377C99.775 10.7377 100.957 11.7284 101.053 13.486H97.6019C92.233 13.486 89.9001 15.4674 89.9001 18.6631C89.9001 21.6671 92.2011 24 96.3555 24C98.8801 24 100.606 23.1372 101.469 21.4754V23.7124H107.125V14.0932C107.125 8.59654 103.897 6.10386 98.273 6.10386ZM98.0493 20.1651C96.6112 20.1651 95.7803 19.4301 95.7803 18.4075C95.7803 17.3529 96.4833 16.6498 98.4327 16.6498H101.053V18.1518C100.574 19.526 99.3915 20.1651 98.0493 20.1651Z"
                fill="#fff"
              />
              <path
                d="M115.304 8.46871V6.39148H109.52V23.7124H115.592V15.755C115.592 12.719 117.285 11.4727 119.746 11.4727C120.193 11.4727 120.577 11.5047 121.12 11.5686V6.10386C118.564 6.10386 116.55 6.9028 115.304 8.46871Z"
                fill="#fff"
              />
              <path
                d="M139.736 15.0839C139.736 9.36351 135.582 6.10386 130.469 6.10386C125.004 6.10386 120.945 9.81092 120.945 15.02C120.945 20.1971 124.94 24 131.108 24C134.399 24 136.828 23.0413 138.49 21.1877L135.294 17.8642C134.112 18.8868 132.993 19.3662 131.299 19.3662C129.062 19.3662 127.56 18.3435 127.049 16.5859H139.64C139.672 16.1065 139.736 15.5313 139.736 15.0839ZM130.5 10.4181C132.386 10.4181 133.728 11.5686 134.048 13.3901H126.953C127.305 11.5686 128.615 10.4181 130.5 10.4181Z"
                fill="#fff"
              />
              <path
                d="M143.655 24C145.732 24 147.298 22.498 147.298 20.3888C147.298 18.2157 145.732 16.8096 143.655 16.8096C141.61 16.8096 140.012 18.2157 140.012 20.3888C140.012 22.498 141.61 24 143.655 24Z"
                fill="#FF6644"
              />
              <script xmlns="" />
            </svg>
          </a>
        </div>
        <div className="flex gap-6">
          <a href="/">
            <TiktokIcon />
          </a>
          <a href="/">
            {" "}
            <InstagramIcon />{" "}
          </a>
          <a href="/">
            {" "}
            <FacebookIcon />{" "}
          </a>
        </div>
        <p className="text-white font-medium">
          ©  {year} – Proshare. All Rights Reserved
        </p>
      </section>
    </section>
  );
};

export default Footer;
