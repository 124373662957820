import { createContext, useEffect, useState } from "react";
import { useStoryblok } from "@storyblok/react";

export const AppContext = createContext();

const CMSContextProvider = ({ children }) => {

  const story = useStoryblok("reviews-page"); // Call the hook at the top level
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (story) {
      setData(story);
      setLoading(false);
    } else {
      setError("Error fetching story");
      setLoading(false);
    }
  }, [story]);


  return (
    <AppContext.Provider value={{ data, loading, error }}>
      {children}
    </AppContext.Provider>
  );
};

export default CMSContextProvider;
